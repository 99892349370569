import { useState } from "react";
import API_URL from "../../config";
import {
  MDBBtn,
  MDBInputGroup,
  MDBListGroupItem,
  MDBListGroup,
  MDBSpinner,
} from "mdb-react-ui-kit";
const AddCar = (props) => {
  const { onClose, jwtToken, onCarAdded } = props;
  const [newCarData, setNewCarData] = useState({
    name: "",
    licence_plate_number: "",
    model: "",
    image: null,
  });
  const [loading, setLoading] = useState(false);

  const handleFormSubmit = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("name", newCarData.name);
    formData.append("licence_plate_number", newCarData.licence_plate_number);
    formData.append("model", newCarData.model);
    formData.append("image", newCarData.image);
  
    try {
      const response = await fetch(`${API_URL}/addCar`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
        body: formData,
      });
  
      if (response.ok) {
        const data = await response.json();
        setNewCarData(data.carData);
        onCarAdded(data.carData[data.carData.length - 1]);
        console.log("Car added successfully", data);
        onClose(); // Close the form after submission
      } else {
        console.error("Failed to add car:", response.statusText);
      }
    } catch (error) {
      console.error("Error making car request:", error);
    } finally {
      setLoading(false);
    }
  };
  

  const handleCarChange = (e) => {
    const { name, value, type } = e.target;

    // For file input, use e.target.files to get the selected file
    const inputValue = type === "file" ? e.target.files[0] : value;

    setNewCarData((prevData) => ({
      ...prevData,
      [name]: inputValue,
    }));
  };
  return (
    <MDBListGroup className="border-none">
      <MDBListGroupItem>
        <MDBInputGroup>
          <div className="custom-file-input ">
            <label htmlFor="image" className="input-group-text stick-bottom">
              اختار صورة للسيارة
            </label>
            <input
              name="image"
              id="image"
              className="form-control border-radius-l-0 w-100 h-42"
              type="file"
              accept=".jpg, .jpeg, .png"
              onChange={handleCarChange}
              required
            />
          </div>
        </MDBInputGroup>
      </MDBListGroupItem>
      <MDBListGroupItem>
        <MDBInputGroup>
          <input
            className="form-control"
            type="text"
            name="name"
            defaultValue={newCarData?.name || ""}
            placeholder="أدخل اسم السيارة"
            onChange={handleCarChange}
          />
        </MDBInputGroup>
      </MDBListGroupItem>
      <MDBListGroupItem>
        <MDBInputGroup>
          <input
            className="form-control"
            name="licence_plate_number"
            type="text"
            defaultValue={newCarData?.licence_plate_number || ""}
            placeholder="أدخل رقم لوحة السيارة"
            onChange={handleCarChange}
          />
        </MDBInputGroup>
      </MDBListGroupItem>
      <MDBListGroupItem>
        <MDBInputGroup>
          <input
            name="model"
            placeholder="أدخل موديل السيارة"
            defaultValue={newCarData?.model || ""}
            className="form-control"
            onChange={handleCarChange}
          />
        </MDBInputGroup>
      </MDBListGroupItem>
      {loading ? ( // Conditionally render spinner if loading is true
        <>
          <MDBSpinner style={{marginRight:'auto',marginLeft:'auto',marginTop:'20px'}} color="primary" />
          <div style={{marginRight:'auto',marginLeft:'auto',marginTop:'20px', marginBottom:'20px'}}>جارٍ تنفيذ الطلب , الرجاء الانتظار...</div>
        </>
      ) : (
        <div className="car-display-flex-row">
          <MDBBtn className="add-car-btn" onClick={handleFormSubmit}>
            اضافة السيارة
          </MDBBtn>
          <MDBBtn className="add-car-btn bg-red" onClick={onClose}>
            الغاء
          </MDBBtn>
        </div>
      )}
    </MDBListGroup>
  );
};

export default AddCar;
