// Filename - components/Sidebar.js
import "./css/Reusable.css";
import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { SidebarData } from "./SidebarData";
import SubMenu from "./SubMenu";
import NavBar from "./Navbar";
import { IconContext } from "react-icons/lib";

const Nav = styled.div`
  background: white;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-bottom: 20px;
`;

const NavIcon = styled(Link)`
  position: relative;
  top: 5px;
  // left: 20px;
  font-size: 2rem;
  height: 80px;
`;

const SidebarWrap = styled.div`
  width: 100%;
  font-weight: bold;
`;

const SideBar = () => {
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => {
    setSidebar(!sidebar);
  };
  const SidebarNav = styled.nav`
  background: #eeee;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: ${({ sidebar }) => (sidebar === 'true' ? '0' : '-100%')};
  transition: 350ms;
  z-index: 10;
`;

  return (
    <>
      <IconContext.Provider value={{ color: "gray" }}>
        <NavBar />
        <Nav>
          <NavIcon to="#">
            <FaIcons.FaBars onClick={showSidebar} />
          </NavIcon>
        </Nav>

        <SidebarNav className="margin-top" sidebar={sidebar.toString()}>
          <SidebarWrap>
            <NavIcon to="#">
              <AiIcons.AiOutlineClose
                className="align-left"
                onClick={showSidebar}
              />
            </NavIcon>
            {SidebarData.map((item, index) => {
              return <SubMenu item={item} key={index} />;
            })}
          </SidebarWrap>
        </SidebarNav>
      </IconContext.Provider>
    </>
  );
};

export default SideBar;
