import STORE_URL from "../../../config2";

const DetailsIdentity = (props) => {
  const { data } = props;
  return (
    <>
      <h1 className="text-center margin-bottom-30"> المستندات</h1>
      <div className="identity-container margin-bottom-50">
        {data.front_id_image && (
          <div className="id-section">
            <label className="margin-bottom-3" htmlFor="front_id">
              (الصورة الأمامية):
            </label>
            <img
              id="front_id"
              src={`${STORE_URL}/${data.front_id_image}`}
              className="img-fluid id-image"
              alt="front_id_image"
            />
          </div>
        )}

        {data.back_id_image && (
          <div className="id-section">
            <label className="margin-bottom-3" htmlFor="back_id">
              (الصورة الخلفية):
            </label>
            <img
              id="back_id"
              src={`${STORE_URL}/${data.back_id_image}`}
              className="img-fluid id-image"
              alt="back_id_image"
            />
          </div>
        )}
      </div>
    </>
  );
};

export default DetailsIdentity;
