import "./css/Home.css";
import SideBar from "../ReusableComponents/SideBar";
import Statistics from "./Statistics";
import ClientsTable from "./ClientsTable";
import NavCards from "./NavCards";
import Footer from "../ReusableComponents/Footer";
import { useNavigate } from "react-router-dom";
import React, { useEffect,useState } from "react";
import API_URL from "../../config";

function HomePage() {
  const navigate = useNavigate();
  const jwtToken = localStorage.getItem("jwt-token");
  const [data, setData] = useState({
    clientsCount: 0,
    carsCount: 0,
    expenses: 0,
    income: 0,
    clients: [],
  });

  useEffect(() => {
    if (!jwtToken) {
      navigate("/login", {replace:true});
    }
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_URL}/`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        });
        if (response.ok) {
          const result = await response.json();
          setData(result);
        } else {
          console.error("Failed to fetch data");
          window.alert("حدث خطأ في محاولة جمع المعلومات!");
        }
      } catch (error) {
        console.error("Error:", error);
        window.alert("حدث خطأ, الرجاء المحاولة لاحقا!");

      }
    };
    fetchData();
    
  }, [jwtToken,navigate]); // Add jwtToken as a dependency
    return (
      <>
        <SideBar />
        <Statistics data={data} />
        <ClientsTable clients={data.clients} />
        <NavCards />
        <Footer />
      </>
    );
  }


export default HomePage;
