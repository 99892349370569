import { useState, useEffect } from "react";
import API_URL from "../../../../config";

const SelectCar = (props) => {
  const {onSelectCarChange} = props
  const [cars, setCars] = useState([]);
  const [selectedCar, setSelectedCar] = useState("");

  useEffect(() => {
    const jwtToken = localStorage.getItem("jwt-token");
    fetch(`${API_URL}/showCars`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCars(data.carData || []);
      })
      .catch((error) => console.error("Error fetching sponsors:", error));
  }, []);

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedCar(selectedValue);
    onSelectCarChange(selectedValue)
  };
  return (
    <>
      <div className="select-container margin-top-40 ">
        <label className="select-label" htmlFor="custom-select">
          اختر سيارة:
        </label>
        <select
          id="custom-select"
          className="custom-select margin-bottom-30"
          value={selectedCar}
          onChange={handleSelectChange}
        >
          <option disabled value="">
            السيارات
          </option>
          {Array.isArray(cars) &&
            cars.length > 0 &&
            cars.map((car) => (
              <option key={car.id} value={car.id} disabled={car.isRented === 1}>
                {car.name} {car.isRented === 1 && " - مأجرة حاليا"}
              </option>
            ))}
        </select>
      </div>
    </>
  );
};

export default SelectCar;
