import React from "react";
import './css/Reusable.css'
import profileImage from './images/profile.png'
import { useNavigate } from 'react-router-dom';
import {
  MDBContainer,
  MDBNavbar,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBInputGroup,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from "mdb-react-ui-kit";

export default function NavBar() {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem('jwt-token');
    navigate('/login');
  };
  const handleHome = () => {
    navigate('/home');
  };
  const handleAddClient = () => {
    navigate('/add-client');
  };
  const handleCars = () => {
    navigate('/cars');
  };
  const handleExpenses = () => {
    navigate('/expenses');
  };
  return (
    <MDBNavbar className="margin-bottom" expand='lg' light bgColor='light'>
      <MDBContainer className="no-wrap" fluid>
      <MDBInputGroup tag="form" className='d-flex w-custom search-width'>
          <input dir="rtl" className='form-control' placeholder="البحث" aria-label="البحث" type='Search' />
        </MDBInputGroup>
        <MDBNavbarNav className='flex-row'>
          <MDBNavbarItem className='me-lg-0'>
              <MDBIcon className="font-size margin-right-10 color-primary" fas icon='bell' />
          </MDBNavbarItem>
          <MDBNavbarItem className='me-lg-0'>
              <MDBIcon className="font-size color-primary" fas icon='gears' />
          </MDBNavbarItem>
          <MDBNavbarItem>
          <MDBDropdown>
                <MDBDropdownToggle tag='a' className='nav-link' role='button'>
                <img className="profile-img" src={profileImage} alt="Profile Navbar" />
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                <div onClick={handleHome} className="flex-row">
                  <MDBIcon className="font-size color-primary" fas icon='home' /><MDBDropdownItem className="align-center font-bold" link >الصفحة الرئيسية</MDBDropdownItem>
                  </div>
                  <div onClick={handleAddClient} className="flex-row">
                  <MDBIcon className="font-size color-primary" fas icon='plus' /><MDBDropdownItem className="align-center font-bold" link >اضافة عميل</MDBDropdownItem>
                  </div>
                  <div onClick={handleCars} className="flex-row">
                  <MDBIcon className="font-size color-primary" fas icon='car' /><MDBDropdownItem className="align-center font-bold" link >السيارات</MDBDropdownItem>
                  </div>
                  <div onClick={handleExpenses} className="flex-row">
                  <MDBIcon className="font-size color-primary" fas icon='dollar' /><MDBDropdownItem className="align-center font-bold" link >مصاريف و مداخيل</MDBDropdownItem>
                  </div>
                  <div onClick={handleLogout} className="flex-row">
                  <MDBIcon className="font-size color-danger" fas icon='sign-out-alt' /><MDBDropdownItem className="align-center font-bold" link >الخروج</MDBDropdownItem>
                  </div>
                </MDBDropdownMenu>
              </MDBDropdown>
              </MDBNavbarItem>
        </MDBNavbarNav>
      </MDBContainer>
    </MDBNavbar>
  );
}
