import "./css/Home.css";
import React from "react";
import { useNavigate } from "react-router-dom";

const ClientsTable = ({clients}) => {
  const navigate = useNavigate();
  function westernToEasternArabicNumerals(input) {
    const easternArabicNumerals = [
      "٠",
      "١",
      "٢",
      "٣",
      "٤",
      "٥",
      "٦",
      "٧",
      "٨",
      "٩",
    ];
    return input.replace(/\d/g, (digit) => easternArabicNumerals[digit]);
  }

  function formatDate(rawDate) {
    const date = new Date(rawDate);
    const year = westernToEasternArabicNumerals(date.getFullYear().toString());
    const month = westernToEasternArabicNumerals(
      (date.getMonth() + 1).toString()
    ); // Adding 1 to match month numbers (0-11)
    const day = westernToEasternArabicNumerals(date.getDate().toString());

    // Use the "dir" attribute for RTL orientation and set it as innerHTML
    return `${day} / ${month} / ${year}`;
  }
  const handleMoreButtonClick = (clientId) => {
    navigate(`/details/${clientId}`);
  };

  return (
    <>
      <div className="table-responsive">
        <table id="myTable" className="table direction">
          <thead>
            <tr>
              <th scope="col">اسم العميل</th>
              <th scope="col">رقم العميل</th>
              <th scope="col">عدد الايجارات</th>
              <th scope="col">مستأجر حاليا</th>
              <th scope="col">تاريخ الاضافة</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {clients.map((client) => (
              <tr key={client.client_id}>
                <td>{client.clientName}</td>
                <td>{client.clientNumber}</td>
                <td>{client.rentingCount}</td>
                <td>{client.rentingNow ? "نعم" : "لا"}</td>
                <td>{formatDate(client.created_at)}</td>
                <td>
                  <button onClick={() => handleMoreButtonClick(client.client_id)} className="button">المزيد</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ClientsTable;
