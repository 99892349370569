const TransactionType = (props) => {
    const {onOptionChange} = props
    const handleRadioChange = (option) => {
        onOptionChange(option);
      };
  return (
    <>
      <div className="flex-gap-20 margin-bottom-30 margin-top-30">
        <input
          type="radio"
          className="btn-check"
          name="type"
          id="option1"
          value="1"
          autoComplete="off"
          onChange={() => handleRadioChange(1)}
        />
        <label className="btn btn-secondary fs-large" htmlFor="option1" data-mdb-ripple-init>
          مدخول
        </label>

        <input
          type="radio"
          className="btn-check"
          name="type"
          value="0"
          id="option2"
          onChange={() => handleRadioChange(0)}
          autoComplete="off"
        />
        <label className="btn btn-secondary fs-large" htmlFor="option2" data-mdb-ripple-init>
          مصروف
        </label>
      </div>
    </>
  );
};

export default TransactionType;
