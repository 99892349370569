import { useState } from "react";
import {
  MDBBtn,
  MDBInputGroup,
  MDBListGroupItem,
  MDBListGroup,
} from "mdb-react-ui-kit";
import TransactionType from "./TransactionType";
import API_URL from "../../config";

const AddExpense = (props) => {
  const { onClose, jwtToken, onExpenseAdded } = props;
  const [newExpenseData, setNewExpenseData] = useState({
    type: null,
    amount: "",
    desc: "",
  });
  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (newExpenseData.type !== 1 && newExpenseData.type !== 0) {
      window.alert("اختر نوع المعاملة قبل الاضافة!");
    } else {
      const formData = new FormData();
      formData.append("type", newExpenseData.type);
      formData.append("desc", newExpenseData.desc);
      formData.append("amount", newExpenseData.amount);

      fetch(`${API_URL}/addTransaction`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          // Update the cars state with the new data
          const lastIndex = data.transactions.length - 1;
          setNewExpenseData((prevExpenses) => [
            ...prevExpenses,
            data.transactions[lastIndex],
          ]);
          onExpenseAdded(
            data.transactions[lastIndex],
            data.revenue,
            data.income,
            data.outcome
          );
          // After submission, you may want to perform additional actions or show a success message
          console.log("Expenses added successfully", data);
          onClose(); // Close the form after submission
        })
        .catch((error) => console.error("Error adding Expense:", error));
    }
  };
  const handleExpenseChange = (e) => {
    const { name, value, type } = e.target;
    // For file input, use e.target.files to get the selected file
    const inputValue = type === "file" ? e.target.files[0] : value;

    setNewExpenseData((prevData) => ({
      ...prevData,
      [name]: inputValue,
    }));
  };
  const handleOptionChangeInParent = (option) => {
    setNewExpenseData((prevExpenses) => ({
      ...prevExpenses,
      type: option,
    }));
  };
  return (
    <>
      <TransactionType onOptionChange={handleOptionChangeInParent} />
      <MDBListGroup className="border-none">
        <MDBListGroupItem>
          <MDBInputGroup>
            <input
              className="form-control"
              type="text"
              name="desc"
              defaultValue={newExpenseData?.desc || ""}
              placeholder="أدخل وصف المعاملة"
              onChange={handleExpenseChange}
            />
          </MDBInputGroup>
        </MDBListGroupItem>
        <MDBListGroupItem>
          <MDBInputGroup>
            <input
              className="form-control"
              name="amount"
              type="text"
              defaultValue={newExpenseData?.amount || ""}
              placeholder="أدخل قيمة المعاملة"
              onChange={handleExpenseChange}
            />
          </MDBInputGroup>
        </MDBListGroupItem>
        <div className="car-display-flex-row">
          <MDBBtn className="add-expense-btn" onClick={handleFormSubmit}>
            اضافة المعاملة
          </MDBBtn>
          <MDBBtn className="add-expense-btn bg-red" onClick={onClose}>
            الغاء
          </MDBBtn>
        </div>
      </MDBListGroup>
    </>
  );
};

export default AddExpense;
