import { useState, useEffect } from "react";
import RentalHeader from "./RentalHeader";
import SelectCar from "./SelectCar";
import DateTime from "./DateTime";
import { MDBBtn, MDBSpinner } from "mdb-react-ui-kit";
import InsuranceVideo from "./InsuranceVideo";
import { useNavigate } from "react-router-dom";
import API_URL from "../../../../config";

const AssignRent = (props) => {
  const { data, jwtToken } = props;
  const navigate = useNavigate();

  // State variable for form data
  const [rentData, setRentData] = useState({
    id: "",
    carId: "",
    startDate: "",
    endDate: "",
    video: "",
  });
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    // Update the id field in rentData when data.id changes
    setRentData((prevRentData) => ({ ...prevRentData, id: data.id }));
  }, [data.id]);

  const handleRentButtonClick = async () => {
    setLoading(true);
    const formDataObject = new FormData();
    formDataObject.append("id", rentData.id);
    formDataObject.append("car_id", rentData.carId);
    formDataObject.append("start_date", rentData.startDate);
    formDataObject.append("end_date", rentData.endDate);
    formDataObject.append("video", rentData.video);
    const formObject = {};
    // Log the FormData entries
    formDataObject.forEach((value, key) => {
      formObject[key] = value;
    });
    console.log("Form Data:", formObject);
    try {
      // Make the POST request
      const response = await fetch(`${API_URL}/newRent`, {
        method: "POST",
        body: formDataObject,
        headers: {
          Authorization: `Bearer ${jwtToken}`, // Include JWT token in the Authorization header
        },
      });

      // Check if the request was successful (status code 2xx)
      if (response.ok) {
        // Handle successful response, if needed
        console.log("Rent request successful!");
        navigate("/home", { replace: true });
      } else {
        // Handle error response
        console.error("Rent request failed:", response.statusText);
      }
    } catch (error) {
      // Handle network or other errors
      console.error("Error making rent request:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  // Update form data when inputs change
  const updateRentData = (field, value) => {
    setRentData((prevRentData) => ({ ...prevRentData, [field]: value }));
  };

  const handleCarChange = (selectedCar) => {
    updateRentData("carId", selectedCar);
  };
  const handleStartDateChange = (promptedStartDate) => {
    // Check if promptedStartDate is a valid date string
    const isValidDate = !isNaN(Date.parse(promptedStartDate));

    if (isValidDate) {
      updateRentData("startDate", promptedStartDate);
      console.log(promptedStartDate); // Check the formatted date
    } else {
      // Set a default or empty string if the date is not valid
      updateRentData("startDate", "");
    }
  };
  const handleEndDateChange = (promptedEndDate) => {
    // Check if promptedStartDate is a valid date string
    const isValidDate = !isNaN(Date.parse(promptedEndDate));

    if (isValidDate) {
      updateRentData("endDate", promptedEndDate);
      console.log("Currently", promptedEndDate); // Check the formatted date
      console.log("Expected", promptedEndDate); // Check the formatted date
    } else {
      // Set a default or empty string if the date is not valid
      updateRentData("endDate", "");
    }
  };

  const handleVideoChange = (file) => {
    setRentData({
      ...rentData,
      video: file,
    });
  };
  return (
    <>
      <div className="text-center padding-bottom-50">
        <RentalHeader />
        <SelectCar onSelectCarChange={handleCarChange} />
        <DateTime
          onStartDate={handleStartDateChange}
          onEndDate={handleEndDateChange}
        />
        <hr></hr>
        <InsuranceVideo onVideoChange={handleVideoChange} />
        {loading ? ( // Conditionally render spinner if loading is true
          <>
            <MDBSpinner color="primary" className="me-2" />
            <div>جارٍ تنفيذ الطلب , الرجاء الانتظار...</div>
          </>
        ) : (
          <MDBBtn
            className="margin-bottom-30 fs-large"
            onClick={handleRentButtonClick}
          >
            أجر السيارة
          </MDBBtn>
        )}
      </div>
    </>
  );
};

export default AssignRent;
