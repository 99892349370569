const ExpensesTransactions = (props) => {
  const { transactions } = props;
  return (
    <>
      <div className="ex_user_details margin-bottom-50">
        <h2 style={{marginTop:"20px", marginBottom: "20px", alignSelf:'flex-start', marginRight:'15px' }}>المعاملات:</h2>

        <div className="ex-display-flex" style={{ width: "100%", overflowY: "auto", maxHeight: "400px" }}>
          <ul style={{width:"90%"}} className="list-group list-group-light">
            {transactions && transactions.map((transaction) => (
              <li
                key={transaction.id}
                className="list-group-item px-3 fw-bold flex-between margin-bottom-30"
              >
                <p className="fs-large">{transaction.trans_desc}</p>
                <p className="fs-large margin-left-25 margin-bottom-none">${transaction.trans_amount}</p>
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    left: 0, // Adjusted to "left" for RTL
                    width: "20px", // Adjust the width as needed
                    backgroundColor:
                      transaction.trans_type === 0 ? "red" : "green",
                    borderRadius: "10px 0 0 10px", // Top-left and bottom-left border radius
                  }}
                ></div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default ExpensesTransactions;
