
const ExpensesTotal = (props) => {
    const {total, income, expense} = props
  return (
    <>
      <div className="ex_user_details">
        <h2 style={{ marginBottom: "50px" }}>المصاريف والمداخيل</h2>
        <div className="card" style={{ width: "18rem" }}>
          <ul className="list-group list-group-light">
            <li className="list-group-item px-3  fw-bold flex-between">
              <p className="fs-larger">المجموع</p>
              <p dir="ltr" className="fs-larger">{total.toFixed(2)}$</p>
            </li>

            <li className="list-group-item px-3 fs-large fw-bold  flex-between">
              <p className="color-green">المدخول</p>
              <p className="color-green">${income.toFixed(2)}</p>
            </li>

            <li className="list-group-item px-3 fs-large fw-bold color-red flex-between">
              <p className="color-red">المصروف</p>
              <p className="color-red">${expense.toFixed(2)}</p>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default ExpensesTotal;
