import { MDBCardBody } from "mdb-react-ui-kit";
import SelectSponsor from "./SelectSponsor";
import SponsorOptions from "./SponsorOptions";
import CreateSponsor from "./CreateSponsor";
import { useState } from "react";
const ClientSponsor = (props) => {
  const { sponsor_id, sponsor_name, sponsor_number, handleInputChange, onOptionChange, onSponsorChangeInSuperParent  } = props;
  const [selectedOption, setSelectedOption] = useState(null);
  const handleOptionChange = (option) => {
    setSelectedOption(option);
    onOptionChange(option);
    console.log(option)
  };
  
  return (
    <MDBCardBody className="align-center">
      <h4>اضافة معلومات الكفيل</h4>

      <SponsorOptions onOptionChange={handleOptionChange} sponsor_id = {sponsor_id} />
      {selectedOption === "exists" && <SelectSponsor onSponsorChangeInSuperParent={onSponsorChangeInSuperParent} />}
      {selectedOption === "new" && (
        <CreateSponsor
          sponsorName={sponsor_name}
          sponsorNumber={sponsor_number}
          handleInputChange={handleInputChange}
        />
      )}
    </MDBCardBody>
  );
};

export default ClientSponsor;
