import SideBar from "../ReusableComponents/SideBar";
import { MDBBtn } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddCar from "./AddCar";
import CarCard from "./CarCard";
import "./css/Cars.css";
import Footer from "../ReusableComponents/Footer";
import API_URL from "../../config";

const Cars = () => {
  const [cars, setCars] = useState([]);
  const [showAddCarForm, setShowAddCarForm] = useState(false);
  const jwtToken = localStorage.getItem("jwt-token");
  const navigate = useNavigate()

  useEffect(() => {
    if(!jwtToken){
      navigate("/login", { replace: true });
    } else {
      fetch(`${API_URL}/showCars`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setCars(data.carData || []);
        })
        .catch((error) => console.error("Error fetching sponsors:", error));
    }
  }, [jwtToken, navigate]);
  
  const onCarAdded = (newCar) => {
    setCars((prevCars) => [...prevCars, newCar]);
  };
  return (
    <>
      <SideBar />
      <div className="display-flex-center">
        {!showAddCarForm && <MDBBtn style={{width:'90%'}}
          className="fs-large margin-bottom-30 w-90"
          onClick={() => setShowAddCarForm(true)}
        >
          أضف سيارة جديدة
        </MDBBtn>}
         {showAddCarForm && <AddCar jwtToken={jwtToken} onClose={() => setShowAddCarForm(false)} onCarAdded={onCarAdded} />}
      </div>
      <div className="display-flex-center">
        <CarCard cars = {cars}/>
      </div>
      <Footer />
    </>
  );
};

export default Cars;
