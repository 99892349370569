import { useEffect, useState } from "react";

const DateTime = (props) => {
  const {onStartDate, onEndDate} = props
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    // Get today's date and time in the Beirut, Lebanon timezone (Asia/Beirut)
    const now = new Date();
    const options = { timeZone: "Asia/Beirut" };
    const formattedToday = now.toLocaleString("en-US", { ...options, timeStyle: "short" });
    setStartDate(formattedToday);
    setEndDate(formattedToday);
  }, []);

  const handleStartDateChange = (e) => {
    const newStartDate = e.target.value;
    setStartDate(newStartDate);
    onStartDate(newStartDate)
  
    // Ensure that the end date is not earlier than the start date
    if (endDate === null || new Date(newStartDate) > new Date(endDate)) {
      setEndDate(newStartDate);
      onEndDate(endDate)
     
    }
  };

  const handleEndDateChange = (e) => {
    const newEndDate = e.target.value;
    setEndDate(newEndDate);
    onEndDate(newEndDate);
  };

  return (
    <>
      <div className="form-container">
        <label htmlFor="start-date" className="label">
          تاريخ بداية الايجار
        </label>
        <input
          className="datetime-input"
          type="datetime-local"
          id="start-date"
          name="start_date"
          value={startDate || ""}
          onChange={handleStartDateChange}
        />
        <label htmlFor="end-date" className="label margin-top-40">
          تاريخ نهاية الايجار
        </label>
        <input
          className="datetime-input margin-bottom-30"
          type="datetime-local"
          id="end-date"
          name="end_date"
          value={endDate || ""}
          min={startDate}
          onChange={handleEndDateChange}
        />
      </div>
    </>
  );
};

export default DateTime;
