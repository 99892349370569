import {
  MDBInputGroup,
  MDBRadio,
} from "mdb-react-ui-kit";

const SponsorOptions = (props) => {
    const { onOptionChange } = props
    const handleRadioChange = (option) => {
        onOptionChange(option);
      };
  return (
    <>
      <MDBInputGroup className="mb-3 margin-top-20 justify-center gap-60">
        <div className="flex-column">
          <div>
            <label
              htmlFor="inlineRadio1"
              className="form-label fs-large w-70 fw-bold"
            >
              اختر الكفيل
            </label>
          </div>
          <div className="align-baseline">
            <MDBRadio
              name="sponsor_status"
              id="inlineRadio1"
              value="exists"
              onChange={() => handleRadioChange("exists")}
              inline
            />
          </div>
        </div>
        <div className="flex-column">
          <div>
            <label
              htmlFor="inlineRadio1"
              className="form-label fs-large w-70 fw-bold"
            >
              كفيل جديد
            </label>
          </div>
          <div className="align-baseline">
            <MDBRadio
              name="sponsor_status"
              id="inlineRadio1"
              value="new"
              onChange={() => handleRadioChange("new")}
              inline
            />
          </div>
        </div>
        <div className="flex-column">
          <div>
            <label
              htmlFor="inlineRadio1"
              className="form-label fs-large w-70 fw-bold"
            >
              ليس لديه كفيل
            </label>
          </div>
          <div className="align-baseline">
            <MDBRadio
              name="sponsor_status"
              id="inlineRadio1"
              value="without"
              onChange={() => handleRadioChange("without")}
              inline
            />
          </div>
        </div>
      </MDBInputGroup>
    </>
  );
};

export default SponsorOptions;
