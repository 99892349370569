import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import SideBar from "../ReusableComponents/SideBar";
import RentalsTable from "./RentalsTable";
import API_URL from '../../config'

const CarDetails = () => {
  const navigate = useNavigate();
  const { carid } = useParams();
  const [details, setDetails] = useState([
    {
      id: "",
      client_name: "",
      client_number: "",
      sponsor_name: "",
      sponsor_number: "",
      start_date: "",
      end_date: "",
      insurance_video: "",
    },
  ]);
  const jwtToken = localStorage.getItem("jwt-token");
  useEffect(() => {
    if (!jwtToken) {
      navigate("/login", { replace: true });
    }
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_URL}/details/${carid}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        });
        if (response.ok) {
          const result = await response.json();
          setDetails(result);
        } else {
          console.error("Failed to fetch data");
          window.alert("حدث خطأ في محاولة جمع المعلومات!");
        }
      } catch (error) {
        console.error("Error:", error);
        window.alert("حدث خطأ, الرجاء المحاولة لاحقا!");
      }
    };
    fetchData();
  }, [jwtToken, navigate, carid]);

  return (
    <>
      <SideBar />
      <RentalsTable details={details} />
    </>
  );
};

export default CarDetails;
