
const PersonalDetails = (props) => {
    const {data} = props
    return (
        <>
             <div className="card_count">
          <div className="count">
            <div className="fans">
              <h3>رقم الهاتف</h3>
              <p>{data.phone}</p>
            </div>
          
            <div className="post">
              <h3>مستأجر حاليا</h3>
              <p>{data.hasRented ? "نعم" : "لا"}</p>
            </div>
          </div>
          <div className="count">
            <div className="fans">
              <h3>لديه كفيل</h3>
              <p>{data.sponsor_name !== null ? "نعم" : "لا"}</p>
            </div>
            <div className="following">
              <h3>اسم الكفيل</h3>
              <p>
                {data.sponsor_name !== null
                  ? data.sponsor_name
                  : "غير موجود"}
              </p>
            </div>
            <div className="post">
              <h3>رقم الكفيل</h3>
              <p>
                {data.sponsor_number !== null
                  ? data.sponsor_number
                  : "غير موجود"}
              </p>
            </div>
           
          </div>
          <div className="following">
              <h3>العنوان</h3>
              <p>{data.address}</p>
            </div>
          <hr></hr>
        </div>
        </>
    );
}

export default PersonalDetails;