import "./css/Home.css";
import React from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCol,
  MDBCardBody,
  MDBIcon,
} from "mdb-react-ui-kit";

function Statistics({ data }) {
  return (
    <MDBContainer fluid>
      <MDBRow className="justify-content-center">
        <MDBCol md="10">
          <section>
            <MDBRow>
              <MDBCol md="4" className="mb-md-0 mb-4">
                <MDBCard className="flex-row">
                  <MDBCardBody>
                    <p className="mb-1 font-weight-bold">عدد العملاء</p>
                    <h2 className="mb-0 text-primary">{data.clientsCount}</h2>
                  </MDBCardBody>
                  <MDBIcon
                    className="margin-left text-primary"
                    size="2x"
                    icon="users"
                  />
                </MDBCard>
              </MDBCol>

              <MDBCol md="4" className="mb-md-0 mb-4">
                <MDBCard className="flex-row">
                  <MDBCardBody>
                    <p className="mb-1 font-weight-bold">عدد السيارات</p>
                    <h2 className="mb-0 text-primary">{data.carsCount}</h2>
                  </MDBCardBody>
                  <MDBIcon
                    className="margin-left text-primary"
                    size="2x"
                    icon="car"
                  />
                </MDBCard>
              </MDBCol>

              <MDBCol md="4" className="mb-md-0 mb-4">
                <MDBCard className="flex-row">
                  <MDBCardBody>
                    <p className="mb-1 font-weight-bold">المصروف</p>
                    <h2 className="mb-0 text-primary">{data.expenses}$</h2>
                  </MDBCardBody>
                  <MDBIcon
                    className="margin-left text-primary"
                    size="2x"
                    icon="file-invoice-dollar"
                  />
                </MDBCard>
              </MDBCol>
              <MDBCol md="4" className="mb-md-0 mb-4">
                <MDBCard className="flex-row">
                  <MDBCardBody>
                    <p className="mb-1 font-weight-bold">المدخول</p>
                    <h2 className="mb-0 text-primary">{data.income}$</h2>
                  </MDBCardBody>
                  <MDBIcon
                    className="margin-left text-primary"
                    size="2x"
                    icon="hand-holding-usd"
                  />
                </MDBCard>
              </MDBCol>
            </MDBRow>
          </section>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

export default Statistics;
