import React, { useState, useEffect } from "react";
import API_URL from "../../../../config";

const SelectSponsor = (props) => {
  const { onSponsorChangeInSuperParent } = props;
  const [sponsors, setSponsors] = useState([]);
  const [selectedSponsor, setSelectedSponsor] = useState("");

  useEffect(() => {
    const jwtToken = localStorage.getItem("jwt-token");
    fetch(`${API_URL}/addClient`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => setSponsors(data.sponsors))
      .catch((error) => console.error("Error fetching sponsors:", error));
  }, []);

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedSponsor(selectedValue);
    // Pass the selected value to the parent component
    onSponsorChangeInSuperParent(selectedValue);
  };

  return (
    <>
      <div className="select-container margin-top-40">
        <label className="select-label" htmlFor="custom-select">
          اختر كفيل:
        </label>
        <select
          id="custom-select"
          className="custom-select"
          value={selectedSponsor}
          onChange={handleSelectChange}
        >
          <option disabled value="">
            الكفلاء
          </option>
          {sponsors.map((sponsor) => (
            <option key={sponsor.id} value={sponsor.id}>
              {sponsor.name}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export default SelectSponsor;
