import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./css/Clients.css";
import AddClientImage from "../images/add-client-img.png";
import ClientSponsor from "./Sponsors/ClientSponsor";
import ClientIdentity from "./ClientIdentity";
import ClientHeader from "./ClientHeader";
import ClientInputs from "./ClientInputs";
import Sidebar from "../../ReusableComponents/SideBar";
import { MDBBtn, MDBCard, MDBCardBody, MDBCardImage,MDBSpinner } from "mdb-react-ui-kit";
import Footer from "../../ReusableComponents/Footer";
import API_URL from "../../../config";

export default function AddClient() {
  const navigate = useNavigate();
  const jwtToken = localStorage.getItem("jwt-token");
  useEffect(() => {
    if (!jwtToken) {
      navigate("/login", { replace: true });
    }
  }, [jwtToken, navigate]);

  const [clientData, setClientData] = useState({
    name: "",
    address: "",
    phone: "",
    sponsor_status: "",
    sponsor_id: "",
    sponsor_name: "",
    sponsor_number: "",
    front_id_image: null,
    back_id_image: null,
  });
  const [loading, setLoading] = useState(false);

  const handleOptionChangeInParent = (option) => {
    setClientData((prevClientData) => ({
      ...prevClientData,
      sponsor_status: option,
    }));
  };

  const handleInputChange = (e) => {
    if (e.target.type === "file") {
      // For file inputs
      setClientData({
        ...clientData,
        [e.target.name]: e.target.files.length > 0 ? e.target.files[0] : null,
      });
      console.log(e.target.files[0]);
    } else {
      setClientData({
        ...clientData,
        [e.target.name]: e.target.value,
      });
      console.log(e.target.value);
    }
  };

  const handleSponsorChangeInSuperParent = (selectedValue) => {
    setClientData((prevClientData) => ({
      ...prevClientData,
      sponsor_id: selectedValue,
    }));
  };

  const handleAddClient = async () => {
    setLoading(true);
    var formData = new FormData();
    formData.append("name", clientData.name);
    formData.append("phone", clientData.phone);
    formData.append("address", clientData.address);
    formData.append("sponsor_status", clientData.sponsor_status);
    formData.append("sponsor_id", clientData.sponsor_id);
    formData.append("sponsor_name", clientData.sponsor_name);
    formData.append("sponsor_number", clientData.sponsor_number);
    formData.append("front_id_image", clientData.front_id_image);
    formData.append("back_id_image", clientData.back_id_image);
    const formObject = {};
    formData.forEach((value, key) => {
      formObject[key] = value;
    });

    // Log the form data
    console.log("Form Data:", formObject);
    try {
      const response = await fetch(`${API_URL}/addClient`, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
        method: "POST",
        body: formData,
        redirect: "manual", // Prevent automatic redirection
      });

      if (response.ok) {
        // Handle successful response, e.g., show a success message
        console.log("Client added successfully");
        navigate("/home", { replace: true });
      } else {
        // Handle error response
        console.error("Error adding client:", response);
        setLoading(false);

        window.alert("فشلت محاولة اضافة العميل");
      }
    } catch (error) {
      console.error("Error adding client:", error.message);
      window.alert("حدث خطأ، الرجاء المحاولة لاحقًا");
      setLoading(false);
    }
  };
  return (
    <>
      <Sidebar />
      <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
        <MDBCard>
          <MDBCardImage
            position="top"
            alt="add-client-profile"
            src={AddClientImage}
          />
          <MDBCardBody>
            <ClientHeader />
            <hr></hr>
            <ClientInputs
              clientData={clientData}
              handleInputChange={handleInputChange}
            />
            <hr></hr>
            <ClientIdentity handleInputChange={handleInputChange} />
            <hr></hr>
            <ClientSponsor
              sponsor_status={clientData.sponsor_status}
              sponsor_name={clientData.sponsor_name}
              sponsor_id={clientData.sponsor_id}
              sponsor_number={clientData.sponsor_number}
              handleInputChange={handleInputChange}
              onOptionChange={handleOptionChangeInParent}
              onSponsorChangeInSuperParent={handleSponsorChangeInSuperParent}
            />
          </MDBCardBody>
          {loading ? ( // Conditionally render spinner if loading is true
            <>
              <MDBSpinner  style={{marginRight:'auto',marginLeft:'auto'}} color="primary"  />
              <div style={{marginRight:'auto',marginLeft:'auto',marginTop:'20px', marginBottom:'20px'}}>جارٍ تنفيذ الطلب , الرجاء الانتظار...</div>
            </>
          ) : (
            <MDBBtn className="add-client-btn" onClick={handleAddClient}>
              اضافة العميل
            </MDBBtn>
          )}
        </MDBCard>
      </div>
      <Footer />
    </>
  );
}
