import {
  MDBCardBody,
  MDBCardLink,
  MDBInputGroup,
  MDBBtn,
} from "mdb-react-ui-kit";

const ClientIdentity = (props) => {
  const { handleInputChange } = props;
  return (
    <>
      <MDBCardBody>
        <h5>* صورة الجهة الأمامية للهوية: </h5>
        <MDBCardLink href="#">
          <MDBInputGroup className="mb-3 margin-top-20">
            <input
              name="front_id_image"
              className="form-control border-radius-l-0 w-100 h-42"
              type="file"
              accept=".jpg, .jpeg, .png"
              onChange={handleInputChange}
              required
            />
            <MDBBtn
              className="remove-button border-radius-r-0 border-radius-l-9 fw-bold"
              outline
              color="secondary"
            >
              ازالة الصورة
            </MDBBtn>
          </MDBInputGroup>
        </MDBCardLink>
        <h5 className="margin-top-20">
          {" "}
          * صورة الجهة الخلفية للهوية (اختياري - إذا كان المستند جواز سفر، رخصة
          قيادة أو اخراج قيد):{" "}
        </h5>
        <MDBCardLink href="#">
          <MDBInputGroup className="mb-3 margin-top-20">
            <input
              name="back_id_image"
              className="form-control border-radius-l-0 w-100 h-42"
              type="file"
              accept=".jpg, .jpeg, .png"
              onChange={handleInputChange}
            />
            <MDBBtn
              className="remove-button border-radius-r-0 border-radius-l-9 fw-bold"
              outline
              color="secondary"
            >
              ازالة الصورة
            </MDBBtn>
          </MDBInputGroup>
        </MDBCardLink>
      </MDBCardBody>
    </>
  );
};

export default ClientIdentity;
