// App.js
import React from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import LoginPage from "./components/Authentication/LoginPage";
import RegistrationPage from "./components/Authentication/RegistrationPage";
import HomePage from "./components/Home/HomePage";
import AddClient from './components/Clients/AddClient/AddClient';
import Cars from './components/Cars/Cars';
import Expenses from './components/Expenses/Expenses';
import ClientDetails from "./components/Clients/GetDetails/ClientDetails";
import CarDetails from "./components/Cars/CarDetails";

const App = () => {
  return (
    <Router>
      <div style={{ overflowY: 'auto', height: '100vh' }}>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegistrationPage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/details/:clientId" element={<ClientDetails />} />
          <Route path="/cardetails/:carid" element={<CarDetails />} />
          <Route path="/add-client" element={<AddClient />}/>
          <Route path="/cars" element={<Cars />}/>
          <Route path="/expenses" element={<Expenses />}/>
	      </Routes>
      </div>
    </Router>
  );
};

export default App;
