import "./css/Expenses.css";
import ExpensesHeader from "./ExpensesHeader";
import ExpensesTotal from "./ExpensesTotal";
import SideBar from "../ReusableComponents/SideBar";
import ExpensesTransactions from "./ExpensesTransactions";
import AddExpense from "./AddExpense";
import { MDBBtn } from "mdb-react-ui-kit";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../ReusableComponents/Footer";
import API_URL from "../../config";
const Expenses = () => {
  const navigate = useNavigate();
  const jwtToken = localStorage.getItem("jwt-token");
  const [showAddExpenseForm, setShowAddExpenseForm] = useState(false);
  const [expenses, setExpenses] = useState({
    revenue: 0,
    income: 0,
    outcome: 0,
    transactions: [],
  });
    useEffect(() => {
        if (!jwtToken) {
            navigate("/login", {replace:true});
          }
        const fetchData = async () => {
          // Fetch data from the backend
          try {
            const response = await fetch(`${API_URL}/expenses`, {
              method: "GET",
              headers: {
                Authorization: `Bearer ${jwtToken}`,
              },
            });
      
            if (response.ok) {
              const result = await response.json();
              setExpenses(result);
            } else {
              console.error("Failed to fetch data");
              window.alert("حدث خطأ في محاولة جمع المعلومات!");
            }
          } catch (error) {
            console.error("Error:", error);
            window.alert("حدث خطأ, الرجاء المحاولة لاحقا!");
          }
        };
      
        fetchData();
      }, [jwtToken, setExpenses, navigate]);
      

  const onExpenseAdded = (newExpense,total, income, outcome) => {
    setExpenses((prevExpenses) => ({
      ...prevExpenses,
      revenue:total,
      income:income,
      outcome:outcome,
      transactions: [...prevExpenses.transactions, newExpense],
    }));
  };
  
  return (
    <>
      <SideBar />
      <ExpensesHeader />
      <ExpensesTotal total = {expenses.revenue} income = {expenses.income} expense = {expenses.outcome} />
      <hr style={{marginTop:'50px'}}></hr>
      <div className="display-flex-center">
        {!showAddExpenseForm && <MDBBtn
          className="fs-large margin-bottom-30 w-90 show-ex-form"
          onClick={() => setShowAddExpenseForm(true)}
        >
          أضف معاملة جديدة
        </MDBBtn>}
         {showAddExpenseForm && <AddExpense jwtToken={jwtToken} onClose={() => setShowAddExpenseForm(false)} onExpenseAdded={onExpenseAdded} />}
      </div>
      <ExpensesTransactions transactions = {expenses.transactions} />
      <Footer />
    </>
  );
};

export default Expenses;
