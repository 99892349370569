import React, { useState } from "react";

const InsuranceVideo = (props) => {
  const { onVideoChange } = props;
  const [selectedVideo, setSelectedVideo] = useState(null);

  const handleVideoChange = (e) => {
    const file = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
    const videoUrl = file ? URL.createObjectURL(file) : null;
  
    // Update local state
    setSelectedVideo(videoUrl);
  
    // Pass the file to the parent component if it exists
    if (file) {
      onVideoChange(file);
    }
  };

  return (
    <>
      <div className="margin-bottom-30 flex-center">
        <label htmlFor="videoInput">اختيار فيديو التأمين</label>
        <input
          className="align-self-end"
          type="file"
          name="video"
          id="videoInput"
          capture="camera"
          accept="video/*"
          onChange={handleVideoChange}
        />

        {selectedVideo && (
          <div>
            <video width="320" height="240" controls>
              <source src={selectedVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        )}
      </div>
    </>
  );
};

export default InsuranceVideo;
