import {
  MDBCardBody,
  MDBCardTitle,
  MDBCardText
} from "mdb-react-ui-kit";

const ClientHeader = () => {
  return (
    <>
      <MDBCardBody className="flex-none">
        <MDBCardTitle className="align-center fw-bold fs-xx-large">
          معلومات العميل
        </MDBCardTitle>
        <MDBCardText className="align-center fs-large">
          هنا يمكنك اضافة عميل جديد و حفظ معلوماته
        </MDBCardText>
      </MDBCardBody>
    </>
  );
};

export default ClientHeader;
