import {
  MDBListGroup,
  MDBInputGroup,
  MDBListGroupItem,
} from "mdb-react-ui-kit";

const ClientInputs = (props) => {
  const { clientData, handleInputChange } = props;
  return (
    <>
      <MDBListGroup className="border-none">
        <MDBListGroupItem>
          <MDBInputGroup>
            <input
              className="form-control"
              type="text"
              name="name"
              defaultValue={clientData?.clientName || ""}
              placeholder="أدخل اسم العميل"
              onChange={handleInputChange}
            />
          </MDBInputGroup>
        </MDBListGroupItem>
        <MDBListGroupItem>
          <MDBInputGroup>
            <input
              className="form-control"
              name="phone"
              type="text"
              defaultValue={clientData?.clientNumber || ""}
              placeholder="أدخل رقم العميل"
              onChange={handleInputChange}
            />
          </MDBInputGroup>
        </MDBListGroupItem>
        <MDBListGroupItem>
          <MDBInputGroup>
            <textarea
              name="address"
              placeholder="أدخل عنوان العميل"
              defaultValue={clientData?.clientAddress || ""}
              className="form-control min-height-100"
              onChange={handleInputChange}
            />
          </MDBInputGroup>
        </MDBListGroupItem>
      </MDBListGroup>
    </>
  );
};

export default ClientInputs;
