import React, { useState } from "react";
import {
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBBtn,
  MDBFooter,
} from "mdb-react-ui-kit";
import LoginImage from "./images/login.png";
import { Link, useNavigate } from "react-router-dom";
import "./css/AuthPages.css";
import API_URL from "../../config";

function LoginPage() {
  localStorage.removeItem("react-auth");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${API_URL}/api/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.token) {
          localStorage.setItem("jwt-token", data.token);
          console.log("Login successful");
          navigate("/home"); // Update with the path of your HomePage route
        } else {
          console.error("Unexpected response format:", data);
          window.alert("تأكد من اسم الحساب وكلمة المرور");
        }
      } else {
        console.error("Server responded with error:", response.status);
        window.alert("حدث خطأ غير متوقع، الرجاء المحاولة لاحقًا");
      }
    } catch (error) {
      console.error("Error:", error);
      window.alert("حدث خطأ، الرجاء المحاولة لاحقًا");
    }
  };

  return (
    <MDBContainer fluid className="p-3 my-5 h-custom">
      <MDBRow className="flex-center">
        <MDBCol col="10" md="6">
          <img src={LoginImage} className="img-fluid app-logo" alt="App Logo" />
        </MDBCol>
        <div>
          <h2 className="login-title">تسجيل الدخول</h2>
        </div>
        <div className="flex-column">
          <input
            className="input"
            type="text"
            placeholder="اسم المستخدم"
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
            }}
          />
          <input
            className="input"
            type="password"
            placeholder="كلمة المرور"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />

          <div className="text-center text-md-start mt-4 pt-2 auth-container">
            <MDBBtn
              onClick={handleFormSubmit}
              className="mb-0 px-5 fw-bold"
              size="lg"
            >
              الاستمرار
            </MDBBtn>
            <div className="register-link-container">
              <Link className="underline" to="/register">
                انشاء حساب{" "}
              </Link>
              <p className="small fw-bold mt-2 mb-2">ليس لديك حساب؟</p>
            </div>
          </div>
        </div>
      </MDBRow>

      <MDBFooter
        bgColor="light"
        className="text-center text-lg-left flex-column footer"
      >
        <div
          className="text-center p-3 width-100-percent"
          style={{ backgroundColor: "#eeeeee" }}
        >
          &copy; {new Date().getFullYear()}© بليق لايجار السيارات. جميع الحقوق
          محفوظة.{" "}
        </div>
      </MDBFooter>
    </MDBContainer>
  );
}

export default LoginPage;
