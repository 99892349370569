import "./css/Details.css";
import SideBar from "../../ReusableComponents/SideBar";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PersonalDetails from "./PersonalDetails";
import DetailsHeader from "./DetailsHeader";
import DetailsIdentity from "./DetailsIdentity";
import RentalDetails from "./RentalDetails";
import AssignRent from "./RentalProcess/AssignRent";
import Footer from "../../ReusableComponents/Footer";
import API_URL from "../../../config";

const ClientDetails = () => {
  const jwtToken = localStorage.getItem("jwt-token");
  const navigate = useNavigate();
  const { clientId } = useParams();
  const [data, setData] = useState({
    clientDetails: [],
  });
  useEffect(() => {
    if (!jwtToken) {
      navigate("/login", { replace: true });
    }
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_URL}/clientDetails/${clientId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
        if (response.ok) {
          const result = await response.json();
          console.log(result);
          setData(result);
        } else {
          console.error("Failed to fetch data");
          console.error("حدث خطأ في محاولة جمع المعلومات!");
        }
      } catch (error) {
        console.error("Error:", error);
        window.alert("حدث خطأ, الرجاء المحاولة لاحقا!");
      }
    };
    fetchData();
  }, [jwtToken, navigate, clientId]); // Add jwtToken as a dependency
  return (
    <>
      <SideBar />
      <div className="card-details margin-bottom-50">
        <DetailsHeader data={data.clientDetails} />
        <PersonalDetails data={data.clientDetails} />
        <DetailsIdentity data={data.clientDetails} />
        <hr></hr>
        {data.clientDetails.hasRented && <RentalDetails data={data.clientDetails} />}
        {data.clientDetails.hasRented === false && <AssignRent  jwtToken = {jwtToken} data={data.clientDetails} />}
      </div>
      <Footer />
    </>
  );
};

export default ClientDetails;
