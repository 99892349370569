import { MDBBtn } from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";
import STORE_URL from '../../config2'

const CarCard = (props) => {
  const { cars } = props;
  const navigate = useNavigate();
  function westernToEasternArabicNumerals(input) {
    const easternArabicNumerals = [
      "٠",
      "١",
      "٢",
      "٣",
      "٤",
      "٥",
      "٦",
      "٧",
      "٨",
      "٩",
    ];
    return input.replace(/\d/g, (digit) => easternArabicNumerals[digit]);
  }

  function formatDate(rawDate) {
    const date = new Date(rawDate);
    const year = westernToEasternArabicNumerals(date.getFullYear().toString());
    const month = westernToEasternArabicNumerals(
      (date.getMonth() + 1).toString()
    ); // Adding 1 to match month numbers (0-11)
    const day = westernToEasternArabicNumerals(date.getDate().toString());
    const hours = westernToEasternArabicNumerals(date.getHours().toString());
    const minutes = westernToEasternArabicNumerals(
      date.getMinutes().toString()
    );
    const seconds = westernToEasternArabicNumerals(
      date.getSeconds().toString()
    );

    // Use the "dir" attribute for RTL orientation and set it as innerHTML
    return `${day} / ${month} / ${year} ${hours}:${minutes}:${seconds}`;
  }
  const handleMoreButtonClick = (carId) => {
    navigate(`/cardetails/${carId}`);
  };
  return (
    <>
      {Array.isArray(cars) &&
        cars.length > 0 &&
        cars.map((car) => (
          <div key={car.id} className="card margin-bottom-30">
            <img
              src={`${STORE_URL}/${car.image}`}
              className="card-img-top"
              alt="car-preview"
            />
            <div className="card-body">
              <h5 className="card-title text-center margin-bottom-30">
                {car.name}
              </h5>
              <div className="display-flex-gap">
                <h6 className="card-text">
                  موديل : <span>{car.model}</span>
                </h6>
                <h6 className="card-text">
                  اللوحة : <span>{car.licence_plate_number}</span>
                </h6>
              </div>
              {car.isRented === 1 ? (
                <h6
                  className={`card-text color-red fs-large text-center margin-top-30`}
                >
                  مأجرة حاليا
                </h6>
              ) : (
                <h6
                  className={`card-text color-green fs-large text-center margin-top-30`}
                >
                  متاحة حاليا
                </h6>
              )}

              <hr></hr>
              {car.isRented === 1 && (
                <>
                  <div className="display-flex-row">
                    <div className="justify-content-col">
                      <h6>اسم العميل</h6>
                      <p className="text-center w-100-px fs-large">
                        {car.client_name}
                      </p>
                    </div>
                    <div className="justify-content-col">
                      <h6>بداية الايجار</h6>
                      <p className="text-center w-100-px fs-large">
                        {formatDate(car.starting_date)}
                      </p>
                    </div>
                    <div className="justify-content-col">
                      <h6>نهاية الايجار</h6>
                      <p className="text-center w-100-px fs-large">
                        {formatDate(car.ending_date)}
                      </p>
                    </div>
                  </div>
                  <hr></hr>
                </>
              )}
              <div style={{ marginTop: "20px" }} className="justify-center">
                <MDBBtn
                  
                  onClick={() => handleMoreButtonClick(car.id)}
                  className="btn btn-primary fs-large"
                  data-mdb-ripple-init
                >
                  تفاصيل السيارة
                </MDBBtn>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export default CarCard;
