import React from "react";
import {
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBBtn,
  // MDBInput,
  MDBFooter
} from "mdb-react-ui-kit";
import LoginImage from "./images/login.png";
import { Link } from "react-router-dom";
import "./css/AuthPages.css";

function RegistrationPage() {
  return (
    <MDBContainer fluid className="p-3 my-5 h-custom">
      <MDBRow>
        <MDBCol col="10" md="6">
          <img src={LoginImage} className="img-fluid app-logo" alt="App Logo" />
        </MDBCol>
        <div>
          <h2 className="login-title">انشاء حساب</h2>
        </div>
        <div>
          <input
            placeholder="اسم المستخدم"
            id="formControlLg"
            type="email"
            className="input"
          />
          <input
            placeholder="كلمة المرور"
            id="formControlLg"
            type="password"
            className="input"
          />
           <input
            placeholder="تأكيد كلمة المرور"
            id="formControlLg"
            type="password"
            className="input"
          />

          <div className="text-center text-md-start mt-4 pt-2 auth-container">
            <MDBBtn className="mb-0 px-5 fw-bold" size="lg">
              انشاء
            </MDBBtn>
            <div className="register-link-container">
              <Link className="underline" to="/login">تسجيل الدخول</Link>
              <p className="small fw-bold mt-2 mb-2">
                 لديك حساب؟
              </p>
            </div>
          </div>
        </div>
      </MDBRow>

      <MDBFooter bgColor='light' className='text-center text-lg-left flex-column footer'>
      <div className='text-center p-3 width-100-percent' style={{ backgroundColor: '#eeeeee' }}>
        &copy; {new Date().getFullYear()}© بليق لايجار السيارات. جميع الحقوق محفوظة.{' '}
       
      </div>
    </MDBFooter>
    </MDBContainer>
  );
}

export default RegistrationPage;
