import './css/Reusable.css'

import React from "react";
// import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
// import * as RiIcons from "react-icons/ri";

export const SidebarData = [
	{
		title: "الصفحة الرئيسية",
		path: "/home",
		icon: <IoIcons.IoMdHome className='margin-left' />,
	},
	{
		title: "اضافة عميل",
		path: "/add-client",
		icon: <IoIcons.IoIosAddCircle className='margin-left' />,
	},
    {
		title: "السيارات",
		path: "/cars",
		icon: <IoIcons.IoIosCar className='margin-left' />,
    },
	{
		title: "المصاريف والمداخيل 	",
		path: "/expenses",
		icon: <AiIcons.AiFillDollarCircle className='margin-left' />,
    }
];
