const ExpensesHeader = () => {
  return (
    <>
      <div className="ex_card_background_img"></div>
      <div className="ex_card_profile_img"></div>
    </>
  );
};

export default ExpensesHeader;
