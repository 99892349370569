const RentalHeader = () => {
  return (
    <>
      <h3 className="margin-right-20 margin-top-40">
        *هل تريد تأجير سيارة لهذا العميل؟
      </h3>
    </>
  );
};

export default RentalHeader;
