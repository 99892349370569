const RentalDetails = (props) => {
  const { data } = props;
  function westernToEasternArabicNumerals(input) {
    const easternArabicNumerals = [
      "٠",
      "١",
      "٢",
      "٣",
      "٤",
      "٥",
      "٦",
      "٧",
      "٨",
      "٩",
    ];
    return input.replace(/\d/g, (digit) => easternArabicNumerals[digit]);
  }

  function formatDate(rawDate) {
    const date = new Date(rawDate);
    const year = westernToEasternArabicNumerals(date.getFullYear().toString());
    const month = westernToEasternArabicNumerals(
      (date.getMonth() + 1).toString()
    ); // Adding 1 to match month numbers (0-11)
    const day = westernToEasternArabicNumerals(date.getDate().toString());
    const hours = westernToEasternArabicNumerals(
      (date.getHours()).toString()
    );
    const minutes = westernToEasternArabicNumerals(
      date.getMinutes().toString()
    );

    // Use the "dir" attribute for RTL orientation and set it as innerHTML
    return `${day} / ${month} / ${year} - ${hours}:${minutes}`;
  }
  return (
    <>
      <div className="rental-container card_count">
        <div className="count-2">
          <div className="fans">
            <h2>السيارة المستأجرة</h2>
            <p className="fs-large">{data.current_rented_car_name}</p>
          </div>
          <div className="following">
            <h2>تاريخ بداية الايجار</h2>
            <p className="fs-large">{formatDate(data.renting_start_date)}</p>
          </div>
          <div className="post">
            <h2>تاريخ نهاية الايجار</h2>
            <p className="fs-large">{formatDate(data.renting_end_date)}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default RentalDetails;
