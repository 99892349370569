import { MDBBtn } from "mdb-react-ui-kit";
import STORE_URL from "../../config2";

const RentalsTable = (props) => {
  const { details } = props;
  function westernToEasternArabicNumerals(input) {
    const easternArabicNumerals = [
      "٠",
      "١",
      "٢",
      "٣",
      "٤",
      "٥",
      "٦",
      "٧",
      "٨",
      "٩",
    ];
    return input.replace(/\d/g, (digit) => easternArabicNumerals[digit]);
  }
  function formatDate(rawDate) {
    const date = new Date(rawDate);
    const year = westernToEasternArabicNumerals(date.getFullYear().toString());
    const month = westernToEasternArabicNumerals(
      (date.getMonth() + 1).toString()
    ); // Adding 1 to match month numbers (0-11)
    const day = westernToEasternArabicNumerals(date.getDate().toString());
    const hours = westernToEasternArabicNumerals(date.getHours().toString());
    const minutes = westernToEasternArabicNumerals(date.getMinutes().toString().padStart(2, '0'));
    // Use the "dir" attribute for RTL orientation and set it as innerHTML
    return `${day} / ${month} / ${year}\n${hours}:${minutes}`;
  }
  return (
    <>
      <div className="table-responsive">
        {details.length === 0 ? (
          <p className="fs-larger" style={{ textAlign: "center" }}>
            لا يوجد ايجارات لهذه السيارة
          </p>
        ) : (
          <table id="myTable" className="table direction">
            <thead>
              <tr>
                <th scope="col">اسم العميل</th>
                <th scope="col">رقم العميل</th>
                <th scope="col">اسم الكفيل</th>
                <th scope="col">رقم الكفيل</th>
                <th scope="col">تاريخ بداية الايجار</th>
                <th scope="col">تاريخ نهاية الايجار</th>
                <th scope="col">فيديو الايجار</th>
              </tr>
            </thead>
            <tbody>
              {details.map((detail) => (
                <tr key={detail.id}>
                  <td>{detail.client_name}</td>
                  <td>{detail.client_number}</td>
                  <td>{detail.sponsor_name !== null ? detail.sponsor_name : 'لا كفيل'}</td>
                  <td>{detail.sponsor_number !== null ? detail.sponsor_number : 'لا كفيل'}</td>
                  <td>{formatDate(detail.start_date)}</td>
                  <td>{formatDate(detail.end_date)}</td>
                  <td>
                    {" "}
                    <MDBBtn
                      style={{ fontSize: "large" }}
                      onClick={() => {
                        window.open(
                          `${STORE_URL}/${detail.insurance_video}`
                        );
                      }}
                    >
                      مشاهدة
                    </MDBBtn>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};

export default RentalsTable;
