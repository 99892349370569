import {
    MDBListGroup,
    MDBInputGroup,
    MDBListGroupItem,
  } from "mdb-react-ui-kit";

const CreateSponsor = (props) => {
    const {sponsorName, sponsorNumber, handleInputChange} = props
  return (
    <>
      <MDBListGroup className="border-none margin-top-40">
        <MDBListGroupItem>
          <MDBInputGroup>
            <input
              className="form-control"
              type="text"
              name="sponsor_name"
              defaultValue={sponsorName || ""}
              placeholder="أدخل اسم الكفيل"
              onChange={handleInputChange}
            />
          </MDBInputGroup>
        </MDBListGroupItem>
        <MDBListGroupItem>
          <MDBInputGroup>
            <input
              className="form-control"
              name="sponsor_number"
              type="text"
              defaultValue={sponsorNumber || ""}
              placeholder="أدخل رقم الكفيل"
              onChange={handleInputChange}
            />
          </MDBInputGroup>
        </MDBListGroupItem>
      </MDBListGroup>
    </>
  );
};

export default CreateSponsor;
