const Footer = () => {
  return (
    <>
      <footer style={{marginTop:'50px'}} className="text-center text-lg-start bg-body-tertiary text-muted">
        <div
          className="text-center p-4"
          style={{backgroundColor:" rgba(0, 0, 0, 0.05)"}}
        >
          © 2024 Copyright:
          <a className="text-reset fw-bold" href="https://blaikRenting.com">
            بليق لايجار السيارات
          </a>
        </div>
      </footer>
    </>
  );
};

export default Footer;
