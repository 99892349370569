const DetailsHeader = (props) => {
    const {data} = props
  return (
    <>
      <div className="card_background_img"></div>
      <div className="card_profile_img"></div>
      <div className="user_details">
        <h2>معلومات العميل</h2>
        <p>{data.name}</p>
      </div>
    </>
  );
};

export default DetailsHeader;
