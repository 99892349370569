import carImage from './images/car-bg.png'
import clientImage from './images/client-bg.png'
import { useNavigate } from 'react-router-dom';
const NavCards = () => {
  const navigate = useNavigate();
  const handleCarsClick = () => {
    navigate('/cars')
  }
  const handleClientsClick = () => {
    navigate('/add-client')
  }
  
  return (
    <>
      <div className="justify-center">
        <div className="bg-image">
          <img
            src={clientImage}
            className="img-fluid"
            alt="Sample"
          />
          <div
            className="mask"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}
          >
            <div className="d-flex justify-content-center align-items-center h-100">
              <button onClick={handleClientsClick} className="text-white mb-0 background-primary">
                اضافة عميل
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="justify-center">
        <div className="bg-image">
          <img
            src={carImage}
            className="img-fluid"
            alt="Sample"
          />
          <div
            className="mask"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}
          >
            <div className="d-flex justify-content-center align-items-center h-100">
              <button onClick={handleCarsClick} className="text-white mb-0 background-primary">
                اضافة سيارة
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavCards;
